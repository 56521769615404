export const exportExcelColumn = [
    {
        field: 'id',
        label: 'Id (unchagneable)'
    },
    {
        field: 'numbering',
        label: 'Numbering'
    },
    {
        field: 'title',
        label: 'Title'
    },
    {
        field: 'station',
        label: 'Station (code)'
    },
    {
        field: 'type',
        label: 'Type (code)'
    },
    {
        field: 'scope',
        label: 'Scope (code)'
    },
    {
        field: 'last_status',
        label: 'Last Status (code)'
    },
    {
        field: 'revision_version',
        label: 'Revision Version'
    },
    {
        field: 'version',
        label: 'Version'
    },
    {
        field: 'receiver',
        label: 'Receiver (changeable)'
    },
    {
        field: 'date_receive',
        label: 'Date Receive (changeable)'
    },
]
